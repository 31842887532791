import Accordian, { AccordianProps } from 'components/styles/general-elements/Accordian';
import TableColumn, { TableColumnProps } from 'components/styles/general-elements/TableColumn';
import Title from 'components/styles/Title';
import React from 'react';
import styled from 'styled-components';
import { Product } from '../types';

const ProductDetailsStyles = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: min-content;
    gap: ${({ theme }) => theme.spacing.xs};
`;

interface ProductDetailsProps {
    accordian?: { title: string; items: AccordianProps['items'] };
    table?: { title: string; items: TableColumnProps['items'] };
    eventDetails?: Product['eventDetails'];
    description?: Product['description'] | string;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ accordian, table, eventDetails, description }) => {
    return accordian?.title ? (
        <ProductDetailsStyles className="ProductDetails-container">
            <>
                {' '}
                <Title as="h4" className="accordian-title">
                    {accordian?.title || 'Details'}
                </Title>
                <Accordian items={accordian?.items} eventDetails={eventDetails} description={description} />
            </>
            {table && (
                <>
                    {' '}
                    <Title as="h4" className="table-title">
                        {table.title}
                    </Title>
                    <TableColumn items={table.items} />
                </>
            )}
        </ProductDetailsStyles>
    ) : null;
};

export default React.memo(ProductDetails);
