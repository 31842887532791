import React from 'react';
import styled from 'styled-components';
import Title from '../Title';

interface TableColumnStyles {
    content?: string;
}

const TableColumnStyles = styled.div<TableColumnStyles>`
    display: grid;
    grid-template-columns: ${({ content }) => (content ? '1fr 1fr' : '1fr')};
    grid-template-rows: auto;

    & > * {
        border-top: 1px solid ${({ theme }) => theme.colors.light_grey_soft_lines};
        padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.xs};
    }
`;
export interface TableColumnProps {
    items: { title: string; content?: string }[];
}

const TableColumn: React.FC<TableColumnProps> = ({ items }) => {
    return (
        <TableColumnStyles className="TableColumn-container" content={items?.[0].content}>
            {items?.map((item, idx) => (
                <React.Fragment key={item.title}>
                    <Title as="h6" className="alt">
                        {item?.title}
                    </Title>
                    {item?.content && <p className="no-style">{item?.content}</p>}
                </React.Fragment>
            ))}
        </TableColumnStyles>
    );
};

export default TableColumn;
