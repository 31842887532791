import React, { useContext, useEffect, useState } from 'react';
import { Q_VARIANT_BY_OPTIONS } from 'src/queries/sanity';
import useSWR, { mutate } from 'swr';
import { getClient } from 'utils/sanity/sanity';
import { SelectedOptionInput } from '../../../src/types/sf-globalTypes';
import { CartContext, UserContext } from '../../../state/context/global';
import { logPDPView, logSingleProductClick } from '../../../utils/analytics';
import MyImage from '../../Layout/MyImage';
import { createSelectedOptionInput } from '../helpers/createOptions';
import { extractImages } from '../helpers/extractImages';
import { formatForCustomization } from '../helpers/updateCustomization';
import { ProductCustomization } from '../interfaces';
import { Product, SanityProductVariantByOptions } from '../types';
import ProductControls from './ProductControls';
import ProductDetails from './ProductDetails';
import ProductMeta from './ProductMeta';

interface SingleProductProps {
    product: Product;
    isSingle?: boolean;
    isPopup?: boolean;
    isRouteModal?: boolean;
    className?: string;
    initialOptions?: SelectedOptionInput[];
}

const SingleProduct: React.FC<SingleProductProps> = ({
    product,
    isSingle,
    className,
    initialOptions,
    isPopup,
    isRouteModal,
}) => {
    const fallbackOpt = createSelectedOptionInput(product, product.store.variants[0]);

    const [customizationMap, setCustomizationMap] = useState<ProductCustomization[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [oosVariant, setOosVariant] = useState(false);
    const [unAvailableVariant, setUnAvailableVariant] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<SelectedOptionInput[]>(initialOptions || fallbackOpt);
    const { data: variantData, error: swrError } = useSWR<SanityProductVariantByOptions>(
        Q_VARIANT_BY_OPTIONS(selectedOptions),
        () =>
            getClient().fetch(Q_VARIANT_BY_OPTIONS(selectedOptions), {
                handle: product.store.slug.current,
                selectedOptions,
            }),
        {
            initialData: {
                id: product.store.gid,
                title: product.store.title,
                handle: product.store.slug.current,
                variantBySelectedOptions: product.store.variants[0],
            },
        },
    );

    const cart = useContext(CartContext);
    const user = useContext(UserContext);
    // const currentVariant = setCurrentVariant(product, variantData, selectedOptions);

    useEffect(() => {
        // this is where we update the variant
        if (variantData && variantData.variantBySelectedOptions && !swrError) {
            formatForCustomization(
                variantData.variantBySelectedOptions,
                product,
                customizationMap,
                setCustomizationMap,
            );
            if (variantData?.variantBySelectedOptions) {
                setSelectedOptions(createSelectedOptionInput(product, variantData?.variantBySelectedOptions));
                setUnAvailableVariant('');
            } else {
                setSelectedOptions(initialOptions || fallbackOpt);
                setUnAvailableVariant(
                    `Due to the seasonal nature of our products, the combination of ${selectedOptions
                        .map((el) => el.name + ': ' + el.value)
                        .join(', and ')} is not currently available.`,
                );
            }
            const oos = !variantData.variantBySelectedOptions.store.inventory.isAvailable;
            const isDefaultOption =
                JSON.stringify(selectedOptions) === JSON.stringify(initialOptions) ||
                JSON.stringify(selectedOptions) === JSON.stringify(fallbackOpt);

            if (oos) {
                setOosVariant(true);
            } else if (!isDefaultOption && variantData?.variantBySelectedOptions === null) {
                setOosVariant(true);
            } else {
                setOosVariant(false);
            }
        }
    }, [variantData]);

    useEffect(() => {
        if (product) {
            const productCustomizationMap: ProductCustomization[] = [
                {
                    title: product.store.title,
                    productId: product.store.gid,
                    currentVariant: variantData?.variantBySelectedOptions || product.store.variants[0],
                    totalPrice: `${(variantData?.variantBySelectedOptions || product.store.variants[0]).store.price}`,
                    quantity: 1,
                    // TODO
                    productShippingRules: [{ name: '' }], // handleProductShippingRules(product),
                    variantShippingRule: '', // handleVariantShippingRule(currentVariant || product.variants.edges[0].node),
                },
            ];
            setCustomizationMap(productCustomizationMap);
        }
    }, [cart.items, variantData]);

    useEffect(() => {
        if (isSingle && user.cookieStatus === 'accept') {
            logPDPView(product, product.store.slug.current);
        }
    }, []);

    const images = extractImages(product);

    return (
        <React.Fragment key={product.store.id}>
            {[product.store.previewImageUrl].length &&
                [product.store.previewImageUrl].map((image, idx) => {
                    if (isSingle && idx === 0)
                        return (
                            <div
                                // TODO
                                role="button"
                                aria-haspopup="dialog"
                                tabIndex={0}
                                key={image}
                                className={`social-image product-image full-bleed`}
                                onClick={() =>
                                    logSingleProductClick(product, undefined, product.store.slug.current, 'imageView')
                                }
                                onKeyDown={() =>
                                    logSingleProductClick(product, undefined, product.store.slug.current, 'imageView')
                                }
                            >
                                {image && (
                                    <MyImage
                                        className={`${oosVariant ? 'out-of-stock' : ''}`}
                                        images={images.main || images.variant}
                                        currentImage={idx}
                                        clickZoom={isSingle}
                                        loader="shopify"
                                        thumbnails={
                                            isSingle ? [product.store.previewImageUrl].map((el) => el) : undefined
                                        }
                                        linkPassThrough={isSingle}
                                        nextImageProps={{
                                            alt: product.store.title,
                                            src: product.store.previewImageUrl,
                                            objectFit: 'cover',
                                            layout: 'fill',
                                            priority: isSingle,
                                        }}
                                    />
                                )}
                            </div>
                        );

                    return null;
                })}

            <ProductMeta
                product={product}
                metaData={{
                    tagline: product.tagline || '',
                    reviews: {
                        count: 8,
                        score: 5,
                    },
                }}
                currentVariant={variantData?.variantBySelectedOptions}
                isSingle={isSingle}
                isPopup={isPopup}
                oosVariant={oosVariant}
            />
            {unAvailableVariant && <p className="card">{unAvailableVariant}</p>}
            {variantData?.variantBySelectedOptions && (
                <ProductControls
                    currentVariant={variantData?.variantBySelectedOptions}
                    product={product}
                    customizationMap={customizationMap}
                    oosVariant={oosVariant}
                    selectedOptions={selectedOptions}
                    isLoading={isLoading}
                    // This should update the SWR Data and local data
                    getVariantByOpt={async (newSelectedOptions) => {
                        setIsLoading(true);
                        await mutate(
                            Q_VARIANT_BY_OPTIONS(newSelectedOptions),
                            () =>
                                getClient()
                                    .fetch(Q_VARIANT_BY_OPTIONS(newSelectedOptions), {
                                        handle: product.store.slug.current,
                                        selectedOptions: newSelectedOptions,
                                    })
                                    .then((res) => {
                                        return res;
                                    }),
                            false,
                        );
                        setTimeout(() => {
                            setIsLoading(false);
                        }, 400);
                    }}
                    setCustomizationMap={setCustomizationMap}
                    isPopup={isPopup}
                />
            )}
            {
                <ProductDetails
                    description={product.description}
                    eventDetails={product.store.productType === 'event' ? product.eventDetails : undefined}
                    accordian={
                        product.accordianFields?.additionalInfoField
                            ? {
                                  title: 'Additional Details',
                                  items: product.accordianFields?.additionalInfoField,
                              }
                            : undefined
                    }
                    table={
                        product.tableFields?.tabularInfoField && product.tableFields.title
                            ? { title: product.tableFields.title, items: product.tableFields.tabularInfoField }
                            : undefined
                    }
                />
            }
        </React.Fragment>
    );
};

export default SingleProduct;
