import { Product } from 'components/shop/types';
import { SelectedOptionInput } from 'src/types/sf-globalTypes';
import { removeDupes } from 'utils/arrayFunctions';
import { string_to_slug } from 'utils/stringFunctions';
import { slugify } from 'utils/stringUtils';

export function genSSProductOptionKey(title: string) {
    return {
        _key: slugify(
            // 'Fionn MacCumhaill'
            title
                .split(' ')
                .map((el) => el.replace(/\W/g, ''))
                .join(' '),
        ),
        value: title,
        // _type: 'shopifyProductOptionValue',
    };
}

function getPossibleVariantCombinations(previousOptionTitle: string, product: Product, idx?: number) {
    // Make a regex from the previous selected option title
    const previousOptionTitleRe = new RegExp(string_to_slug(previousOptionTitle), 'i');

    // Filter out all the variants that don't have the previous title
    const possibleVariants = product.store.variants.filter((variant) => {
        return previousOptionTitleRe.test(string_to_slug(variant.store.title));
    });

    return possibleVariants;
}

export function defaultSelectedOption(
    newOpts: {
        name: string;
        value: any;
    }[],
    product: Product,
): {
    name: string;
    value: any;
}[] {
    const proposedNewOptsTitle = newOpts.map((op) => op.value).join(' / ');

    // Take propsed new option
    // Run against possible vars
    // return best possible option

    // All variants that have the first option
    const possibleVariants = getPossibleVariantCombinations(newOpts[0].value, product);

    // Existing combination is valid?
    const hasExactMatch = possibleVariants.find((el) => el.store.title === proposedNewOptsTitle);

    if (hasExactMatch) {
        return newOpts;
    }

    // Get the first possible Variant
    const defaultOption = possibleVariants[0];

    // Split the title to for all Variant options
    const dfOpts = defaultOption?.store.title.split(' / ');

    // Modify the Options to match the default option
    const modifiedOpts = newOpts.map((o, idx) => ({ ...o, value: dfOpts[idx] }));

    return modifiedOpts;
}

export function setOptionCombinations(
    product: Product,
    selectedOptions: SelectedOptionInput[],
): Product['store']['options'] {
    const modifiedOptValues = product.store.options.map((option, idx, arr) => {
        // If we have more than one option
        // if (idx > 0) {
        //     // Filter out all the variants that don't have the previous title
        //     const possibleVariants = getPossibleVariantCombinations(selectedOptions[idx - 1].value, product, idx);

        //     // TODO Values should be pulled from the product options - Not the title
        //     const newOptValues = possibleVariants.map((pVar) => pVar.title.split('/')[idx].replace(/\s/g, ''));

        //     // Set modified values
        //     const finalOpts = option.values.filter((val) => removeDupes(newOptValues).includes(val.value));

        //     option.values = finalOpts;
        // }
        return option;
    });

    return modifiedOptValues;
}

// export function setOptionCombinations(
//     product: Product,
//     selectedOptions: SelectedOptionInput[],
// ): Product['options'] {
//     const modifiedOptValues = product.options.map((option, idx, arr) => {
//         // devLog('option', option);
//         // devLog('product.store.options', product.store.options);
//         // if option.values is empty

//         if (option.values.length === 0) {
//             const srcData = product.store.options.find((el) => el.name === option.name);
//             devLog('srcData', srcData);
//             if (srcData) {
//                 option.values = srcData.values.map((el) => genSSProductOptionKey(el));
//                 devLog('option.values', option.values);
//             }
//         }
//         // find sourcedata with same name
//         // map over titles and create options array
//         // set as option.values
//         // If we have more than one option
//         if (idx > 0) {
//             // Filter out all the variants that don't have the previous title
//             const possibleVariants = getPossibleVariantCombinations(selectedOptions[idx - 1].value, product, idx);

//             // TODO Values should be pulled from the product options - Not the title
//             const newOptValues = possibleVariants.map((pVar) => pVar.title.split('/')[idx].replace(/\s/g, ''));

//             // Set modified values
//             const finalOpts = option.values.filter((val) => removeDupes(newOptValues).includes(val.value));

//             devLog('finalOpts', finalOpts);
//             // option.values = finalOpts;
//         }
//         devLog('option', option);
//         return option;
//     });

//     return modifiedOptValues;
// }

// export function setOptionCombinations(
//     product: Product,
//     selectedOptions: SelectedOptionInput[],
// ): Product['options'] {
//     const modifiedOptValues = product.store.options
//         .map((option) => ({ ...option, values: option.values.map((opt) => genSSProductOptionKey(opt)) }))
//         .map((option, idx, arr) => {
//             devLog('option', option);
//             // If we have more than one option
//             if (idx > 0) {
//                 // Filter out all the variants that don't have the previous title
//                 const possibleVariants = getPossibleVariantCombinations(selectedOptions[idx - 1].value, product, idx);

//                 // TODO Values should be pulled from the product options - Not the title
//                 const newOptValues = possibleVariants.map((pVar) => pVar.title.split('/')[idx].replace(/\s/g, ''));

//                 // Set modified values
//                 const finalOpts = option.values.filter((val) => removeDupes(newOptValues).includes(val.value));

//                 option.values = finalOpts;
//             }
//             return option;
//         });

//     return modifiedOptValues;
// }
