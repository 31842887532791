import { logSingleProductClick } from '../../../utils/analytics';
import { CustomizationUpdates, ProductCustomization } from '../interfaces';
import { Product, ProductVariant } from '../types';
import { createSelectedOptionInput } from './createOptions';
import { getCustomization } from './getCustomization';

export function updateCustomization(
    product: Product,
    customizationMap: ProductCustomization[],
    updates: CustomizationUpdates[],
    stateFn: (newMap: ProductCustomization[]) => void,
): void {
    const newMap = customizationMap.map((item) => {
        if (item.productId === product.store.gid) {
            for (let i = 0; i < updates.length; i++) {
                item[updates[i].key] = updates[i].value;
            }

            item.totalPrice = (item.currentVariant.store.price * item.quantity).toFixed(2);
        }
        return item;
    });

    stateFn(newMap);
}

export function formatForCustomization(
    variant: ProductVariant,
    product: Product,
    customizationMap: ProductCustomization[],
    stateFn: (newMap: ProductCustomization[]) => void,
) {
    const updates: CustomizationUpdates[] = [
        { key: 'variantPrice', value: variant.store.price },
        { key: 'variantTitle', value: variant.store.title },
        { key: 'variantId', value: variant.store.gid },
        { key: 'value', value: variant.store.gid },
        { key: 'variantShippingRule', value: '' },
        { key: 'selectedOptions', value: createSelectedOptionInput(product, variant) },
    ];

    updateCustomization(product, customizationMap, updates, stateFn);
}

export function updateQuantity(
    product: Product,
    customizationMap: ProductCustomization[],
    action: '+' | '-',
    stateFn: (newMap: ProductCustomization[]) => void,
) {
    const custom = getCustomization(product, customizationMap);
    let amt = custom.quantity;
    if (action === '+') {
        amt++;
    }
    if (action === '-') {
        amt > 0 ? amt-- : amt;
    }

    const updates: CustomizationUpdates[] = [{ key: 'quantity', value: amt }];
    updateCustomization(product, customizationMap, updates, stateFn);
    logSingleProductClick(
        product,
        { quantity: amt, variantId: custom.currentVariant.store.gid },
        product.store.slug.current,
        'modifyProduct',
        () => null,
        'UpdateCustomization',
    );
}
