import rmUndef from '../../../utils/rmUndef';
import { CartItemProductNode } from '../interfaces';
import { Product } from '../types';

export function extractImages(product: Product | CartItemProductNode) {
    const images = { main: [''], variant: [''] };
    if (product.store.previewImageUrl && [product.store.previewImageUrl].length) {
        images.variant = [product.store.previewImageUrl] ? rmUndef(product.store.variants.map((vI) => null)) : [''];
        images.main = [product.store.previewImageUrl]; //product.store.images.edges.map((img) => img.node.originalSrc);
    }

    return images;
}
// export function extractProdImages(
//     prodImages: ProductsData_products_edges_node_images | collections_collections_edges_node_products_edges_node_images,
// ) {
//     const images = { main: [''], variant: null };
//     if (prodImages && prodImages) {
//         images.main = prodImages.edges.map((img) => img.node.originalSrc);
//     }

//     return images;
// }
