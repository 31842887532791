import Button from 'components/Layout/Buttons';
import { MyLink } from 'components/Layout/Link';
import { Column } from 'components/Layout/grid/Column';
import { Row } from 'components/Layout/grid/Row';
import { DropdownIndicator, MyLabel, MySelectWrapper } from 'components/form/FormElements';
import reactSelectReset from 'components/form/reactSelectStyle';
import { device } from 'components/styles/mixins';
import { bbTheme } from 'components/styles/theme';
import React, { useContext } from 'react';
import Select from 'react-select';
import { SelectedOptionInput } from 'src/types/sf-globalTypes';
import { CartContext, MyAppContext } from 'state/context/global';
import styled from 'styled-components';
import QuantitySelector from '../QuantitySelector';
import { addToCart } from '../helpers/addToCart';
import { getCustomization } from '../helpers/getCustomization';
import { isInCart } from '../helpers/handleCartButton';
import { updateQuantity } from '../helpers/updateCustomization';
import { setOptionCombinations } from '../helpers/variants/sortVariantOptions';
import { ProductCustomization } from '../interfaces';
import { Product, ProductVariant } from '../types';
import ThreeDotsWave from 'components/animations/loading/ThreeDotsLoader';

const ProductControlsStyles = styled(Row)`
    margin: 0;
    --btn-min-width: 100%;

    .dropdown__menu {
        z-index: 99;
        border: ${({ theme }) => theme.borders.base};
    }

    .atc-wrapper {
        @media ${device(true).medium_large} {
            display: flex;
            align-items: flex-end;
            padding: ${({ theme }) => theme.spacing.xs};
        }
    }

    .PopupProduct-container & {
        height: 40vh;
        min-height: 30rem;

        padding: 0 ${({ theme }) => theme.spacing.s};
    }

    .option-label {
        display: flex;
        align-items: center;

        & .three-dots-wave {
            position: relative;
            left: 1rem;
        }
    }
`;
interface ProductControlsProps {
    currentVariant: ProductVariant;
    product: Product;
    customizationMap: ProductCustomization[];
    selectedOptions: SelectedOptionInput[];
    getVariantByOpt: (options: SelectedOptionInput[]) => Promise<void>;
    setCustomizationMap: React.Dispatch<React.SetStateAction<ProductCustomization[]>>;
    isPopup?: boolean;
    oosVariant: boolean;
    isLoading: boolean;
}

const ProductControls: React.FC<ProductControlsProps> = ({
    currentVariant,
    product,
    customizationMap,
    selectedOptions,
    getVariantByOpt,
    setCustomizationMap,
    isPopup,
    oosVariant,
    isLoading,
}) => {
    const cart = useContext(CartContext);
    const app = useContext(MyAppContext);

    return (
        <ProductControlsStyles
            breakpoints={[bbTheme.breakpoints.xs, bbTheme.breakpoints.medium_small, bbTheme.breakpoints.medium_large]}
            className="ProductControls-container no-margin"
        >
            {setOptionCombinations(product, selectedOptions).map((option, idx, arr) => {
                if (/Title/i.test(option.name) && option.values.length === 1 && option.values[0] === 'Default Title') {
                    return null;
                }
                if (/Default\sTitle/i.test(option.name)) {
                    option.values = option.values.filter((op) => op === 'Title');
                }
                if (!option.values.length) return null;

                if (option.values.length > 3) {
                    const options = option.values.map((o) => ({ value: o, label: o }));
                    return (
                        <Column
                            //
                            spacing={[12, 0]}
                            widths={[6, 6, 6]}
                            className={`product-option product-option-${idx + 1}`}
                            key={option.values.join('--')}
                        >
                            <MyLabel className="option-label">
                                {/Title/i.test(option.name) ? 'Selection' : option.name}{' '}
                                {isLoading && <ThreeDotsWave fluid />}
                            </MyLabel>
                            <MySelectWrapper key={option.values.join('--')}>
                                <Select
                                    instanceId={'variant-select'}
                                    options={options}
                                    // @ts-ignore
                                    onChange={({ value }) => {
                                        const newOpt: SelectedOptionInput = {
                                            name: option.name,
                                            value,
                                        };

                                        getVariantByOpt(
                                            [
                                                ...selectedOptions.filter((el) => el.name !== option.name),
                                                newOpt,
                                            ].reverse(),
                                        );
                                    }}
                                    value={options.find(
                                        (op) =>
                                            op.value === selectedOptions.find((el) => el.name === option.name)?.value,
                                    )}
                                    styles={reactSelectReset}
                                    defaultValue={options[0]}
                                    className="dropdown-container"
                                    classNamePrefix="dropdown"
                                    components={{ DropdownIndicator }}
                                />
                            </MySelectWrapper>
                        </Column>
                    );
                }

                return (
                    <Column
                        spacing={[12, 0]}
                        widths={[6, 6, 6]}
                        className={`product-option product-option-${idx + 1}`}
                        key={option.values.join('--')}
                    >
                        <MyLabel className="option-label">
                            {/Title/i.test(option.name) ? 'Selection' : option.name}{' '}
                            {isLoading && <ThreeDotsWave fluid />}
                        </MyLabel>

                        {option.values.map((value) => {
                            const newOpt: SelectedOptionInput = {
                                name: option.name,
                                value: value,
                            };
                            const newOptions = [
                                ...selectedOptions.filter((el) => el.name !== option.name),
                                newOpt,
                            ].reverse();

                            return (
                                <Button
                                    key={value}
                                    text={/Default\sTitle/i.test(value) ? product.store.title : value}
                                    btnStyle={
                                        selectedOptions.find((el) => el.value === value && option.name === el.name)
                                            ? 'accent-soft'
                                            : 'secondary'
                                    }
                                    func={() => {
                                        getVariantByOpt(newOptions);
                                    }}
                                />
                            );
                        })}
                    </Column>
                );
            })}

            {oosVariant ? null : (
                <Column className="quantity-selector-wrapper" widths={[6, 6, 6]}>
                    <QuantitySelector
                        className="quantity-selector "
                        increment={() => updateQuantity(product, customizationMap, '+', setCustomizationMap)}
                        decrement={() => updateQuantity(product, customizationMap, '-', setCustomizationMap)}
                        quantity={getCustomization(product, customizationMap).quantity}
                    />
                </Column>
            )}

            <Column
                spacing={[0, 0]}
                //
                className="atc-wrapper"
                widths={[6, 6, 12]}
            >
                {oosVariant ? (
                    <div className="atc-btn buy-now-btn">
                        <p>
                            <strong>Out of Stock - Please try another option</strong>
                        </p>
                    </div>
                ) : isPopup ? (
                    <MyLink
                        className="buy-now-btn"
                        btnStyle="primary"
                        hrefProp={'/cart'}
                        func={() => {
                            getCustomization(product, customizationMap).quantity
                                ? addToCart(product, getCustomization(product, customizationMap), cart.addItem)
                                : null;
                            app.setPopupProductDismissed();
                        }}
                        text={isInCart(cart.items, currentVariant.store.gid || '') ? "Let's Checkout" : 'Buy Now!'}
                    />
                ) : (
                    <Button
                        btnStyle="primary"
                        className="atc-btn "
                        disabled={isInCart(cart.items, currentVariant.store.gid)}
                        func={() =>
                            getCustomization(product, customizationMap).quantity
                                ? addToCart(product, getCustomization(product, customizationMap), cart.addItem)
                                : null
                        }
                        text={isInCart(cart.items, currentVariant.store.gid || '') ? 'In cart' : 'Add to cart'}
                    />
                )}
            </Column>
        </ProductControlsStyles>
    );
};

export default ProductControls;
