import { Product } from 'components/shop/types';
import React, { Fragment } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import { BlockContent } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { PortableText } from 'utils/sanity/sanity';
import { slugify } from 'utils/stringUtils';

const PlusIcon: React.FC = ({}) => {
    return (
        <svg
            className="PlusIcon-container"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 7H13M7 13L7 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
const AccordianStyles = styled.div`
    /**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
    .accordion {
        border-radius: 2px;
    }

    .accordion__item {
        border-top: 1px solid ${({ theme }) => theme.colors.light_grey_soft_lines};
    }

    .accordion__button {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
        cursor: pointer;
        padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.xs};
        width: 100%;
        text-align: left;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .accordion__button:hover {
        background-color: ${({ theme }) => theme.colors.light_grey_soft_lines};
    }

    .PlusIcon-container {
        transition: all 300ms ease-in-out;
        float: right;
    }

    .accordion__button[aria-expanded='true'] .PlusIcon-container,
    .accordion__button[aria-selected='true'] .PlusIcon-container {
        transition: all 300ms ease-in-out;
        transform: rotate(45deg);
    }

    [hidden] {
        display: none;
    }

    .accordion__panel {
        padding: ${({ theme }) => theme.spacing.xs};
        animation: fadein 0.35s ease-in;
    }

    /* -------------------------------------------------- */
    /* ---------------- Animation part ------------------ */
    /* -------------------------------------------------- */

    @keyframes fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`;

export interface AccordianProps {
    description?: Product['description'] | string;
    items?: { title: string; content: BlockContent | string }[];
    eventDetails?: Product['eventDetails'];
}

const Accordian: React.FC<AccordianProps> = ({ items, eventDetails, description }) => {
    return (
        <AccordianStyles className="Accordian-container">
            <Accordion allowZeroExpanded>
                {description && (
                    <AccordionItem key={'product-description'}>
                        <AccordionItemHeading
                            id={`product-details-description-heading`}
                            className="heading-6 alt"
                            aria-level={6}
                        >
                            <AccordionItemButton itemID={`product-accordian-button-description`}>
                                Description <PlusIcon />
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {typeof description === 'string' ? description : <PortableText blocks={description} />}
                        </AccordionItemPanel>
                    </AccordionItem>
                )}
                {eventDetails && (
                    <AccordionItem key={eventDetails.startDate}>
                        <AccordionItemHeading
                            id={`product-details-event-${slugify(eventDetails.startDate)}`}
                            className="heading-6 alt"
                            aria-level={6}
                        >
                            <AccordionItemButton itemID={`product-accordian-button-${eventDetails.startDate}`}>
                                Event Details <PlusIcon />
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                <strong>Date:</strong> <br />
                                <span>{new Date(eventDetails.startDate).toDateString()}</span>
                                {new Date(eventDetails.startDate).toDateString() ===
                                new Date(eventDetails.endDate).toDateString() ? null : (
                                    <>
                                        <br />
                                        <br />
                                        <strong>End Date:</strong> <br />
                                        <span>{new Date(eventDetails.startDate).toDateString()}</span>
                                    </>
                                )}
                                <br /> <br />
                                <strong>Time:</strong> <br />
                                <span>
                                    {new Date(eventDetails.startDate).toLocaleTimeString('IE').replace(/:00\s/, ' ')} to{' '}
                                    {new Date(eventDetails.endDate).toLocaleTimeString('IE').replace(/:00\s/, ' ')}
                                </span>
                                <br />
                                <br />
                                <strong>Address:</strong> <br />
                                {Object.values(eventDetails['address'])
                                    .filter((el) => !/http|www/.test(el || ''))
                                    .map((event) => (
                                        <Fragment key={event}>
                                            <span>{event}</span>
                                            <br />
                                        </Fragment>
                                    ))}
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                )}

                {items &&
                    items?.map((item, idx) => (
                        <AccordionItem key={item.title}>
                            <AccordionItemHeading
                                id={`product-details-${slugify(item.title)}`}
                                className="heading-6 alt"
                                aria-level={6}
                            >
                                <AccordionItemButton itemID={`product-accordian-button-${slugify(item.title)}-${idx}`}>
                                    {item.title} <PlusIcon />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <>
                                    {typeof item.content === 'string' ? (
                                        <p>{item.content}</p>
                                    ) : (
                                        <AccordionItemState>
                                            {({ expanded }) => (expanded ? <PortableText blocks={item.content} /> : '')}
                                        </AccordionItemState>
                                    )}
                                </>
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
            </Accordion>
        </AccordianStyles>
    );
};

export default Accordian;
